/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import '@techniek-team/lyceo-style/src/scss/ionic-lyceo-theme.dark';
@import '@techniek-team/lyceo-style/src/scss/ionic-lyceo-theme';

@import 'theme/ion-card';
@import 'theme/material/mat-theme';

:root {
  --ion-background-color: var(--lyceo-light);
  --ion-background-color-rgb: var(--lyceo-light-rgb);
  --tt-safe-area-bottom: 71px;

  // todo This is temporary and can be removed with the next upgrade of techniek-team/ionic-style package
  --lyceo-color-fr-rgb: 252, 212, 94;
  --lyceo-color-nl-rgb: 241, 139, 102;
  --lyceo-color-en-rgb: 221, 120, 116;
  --lyceo-color-ec-rgb: 156, 212, 214;
  --lyceo-color-sk-rgb: 203, 171, 203;
  --lyceo-color-ak-rgb: 249, 178, 89;
  --lyceo-color-be-rgb: 116, 191, 189;
  --lyceo-color-bi-rgb: 171, 208, 135;
  --lyceo-color-de-rgb: 189, 216, 239;
  --lyceo-color-du-rgb: 189, 216, 239;
  --lyceo-color-gs-rgb: 182, 102, 95;
  --lyceo-color-gr-rgb: 208, 145, 147;
  --lyceo-color-la-rgb: 231, 123, 148;
  --lyceo-color-nask1-rgb: 65, 128, 128;
  --lyceo-color-nask2-rgb: 160, 146, 198;
  --lyceo-color-na-rgb: 150, 193, 168;
  --lyceo-color-wi-rgb: 204, 226, 203;
  --lyceo-color-wa-rgb: 127, 180, 220;
  --lyceo-color-wb-rgb: 118, 140, 198;
  --lyceo-color-wc-rgb: 214, 226, 155;
}

ion-refresher {
  &.md {
    --tt-ion-refresh-background: var(--lyceo-dark-blue);
    --tt-ion-refresh-color: var(--lyceo-dark-blue-contrast);
    --ion-color-step-250: var(--tt-ion-refresh-background);
    --ion-color-step-200: var(--tt-ion-refresh-border-color);
    --tt-ion-refresh-border-color: var(--lyceo-dark-blue);

    ion-spinner {
      /* stylelint-disable-next-line declaration-no-important */
      color: var(--tt-ion-refresh-color) !important;
      /* stylelint-disable-next-line declaration-no-important */
      background: var(--tt-ion-refresh-background) !important;
    }
  }
}

ion-back-button.ios {
  font-size: 14px;
  padding-left: 8px;
}

ion-img {
  width: 100%;
}

tt-avatar ion-img {
  height: 100%;
  object-fit: cover;
}

.fit-content-modal {
  --max-height: 100%;
  --max-width: 100%;
  --height: fit-content;
  --width: fit-content;
  --border-radius: 10px;
}

ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

ion-toast {
  font-size: 0.9rem;
  --white-space: 50px;

  &::part(message) {
    line-height: 1.5rem;
  }

  &::part(button) {
    background: rgba(255, 255, 255, 0.12);
    font-size: 0.9rem;
    padding: 8px;
    border-radius: 4px;
    border: 2px solid rgba(255, 255, 255, 0.32);
    text-transform: none;
    margin: 8px 10px 8px 0px;
  }
}
