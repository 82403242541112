// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** tertiary **/
  --ion-color-tertiary: var(--lyceo-yellow);
  --ion-color-tertiary-rgb: var(--lyceo-yellow-rgb);
  --ion-color-tertiary-contrast: var(--lyceo-yellow-contrast);
  --ion-color-tertiary-contrast-rgb: var(--lyceo-yellow-contrast-rgb);
  --ion-color-tertiary-shade: var(--lyceo-yellow-shade);
  --ion-color-tertiary-tint: var(--lyceo-yellow-tint);
}
