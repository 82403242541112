:root {
  /** Unassigned (Openstaand) **/
  --ion-color-unassigned: #fdeaec;
  --ion-color-unassigned-rgb: 253, 234, 236;
  --ion-color-unassigned-contrast: var(--lyceo-dark-blue);
  --ion-color-unassigned-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-unassigned-shade: #e4253b;
  --ion-color-unassigned-shade-contrast: var(--lyceo-red-contrast);
  --ion-color-unassigned-tint: var(--lyceo-red);

  /** Approached (Benaderd) **/
  --ion-color-approached: #fdeaec;
  --ion-color-approached-rgb: 253, 234, 236;
  --ion-color-approached-contrast: var(--lyceo-dark-blue);
  --ion-color-approached-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-approached-shade: #e4253b;
  --ion-color-approached-shade-contrast: var(--lyceo-red-contrast);
  --ion-color-approached-tint: var(--lyceo-red);

  /** waiting_for_documents (Wachtend op documenten) **/
  --ion-color-waiting-for-documents: #fdeaec;
  --ion-color-waiting-for-documents-rgb: 253, 234, 236;
  --ion-color-waiting-for-documents-contrast: var(--lyceo-dark-blue);
  --ion-color-waiting-for-documents-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-waiting-for-documents-shade: #e4253b;
  --ion-color-waiting-for-documents-shade-contrast: var(--lyceo-red-contrast);
  --ion-color-waiting-for-documents-tint: var(--lyceo-red);

  /** waiting_for_confirmation (Onbevestigd) **/
  --ion-color-waiting-for-confirmation: #e6eaf5;
  --ion-color-waiting-for-confirmation-rgb: 230, 234, 245;
  --ion-color-waiting-for-confirmation-contrast: var(--lyceo-dark-blue);
  --ion-color-waiting-for-confirmation-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-waiting-for-confirmation-shade: var(--lyceo-dark-blue-70);
  --ion-color-waiting-for-confirmation-shade-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-waiting-for-confirmation-tint: var(--lyceo-dark-blue);

  /** draft (Concept) **/
  --ion-color-draft: #fbf5e6;
  --ion-color-draft-rgb: 251, 245, 230;
  --ion-color-draft-contrast: var(--lyceo-dark-blue);
  --ion-color-draft-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-draft-shade: #d69300;
  --ion-color-draft-shade-contrast: var(--lyceo-yellow-contrast);
  --ion-color-draft-tint: var(--lyceo-yellow);

  /** provisionally_confirmed (Voorlopig) **/
  --ion-color-provisionally-confirmed: #fbf5e6;
  --ion-color-provisionally-confirmed-rgb: 251, 245, 230;
  --ion-color-provisionally-confirmed-contrast: var(--lyceo-dark-blue);
  --ion-color-provisionally-confirmed-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-provisionally-confirmed-shade: #d69300;
  --ion-color-provisionally-confirmed-shade-contrast: var(--lyceo-yellow-contrast);
  --ion-color-provisionally-confirmed-tint: var(--lyceo-yellow);

  /** confirmed (Bevestigd) **/
  --ion-color-confirmed: #e8f6f5;
  --ion-color-confirmed-rgb: 232, 246, 245;
  --ion-color-confirmed-contrast: var(--lyceo-dark-blue);
  --ion-color-confirmed-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-confirmed-shade: #11a295;
  --ion-color-confirmed-shade-contrast: var(--lyceo-mint-contrast);
  --ion-color-confirmed-tint: var(--lyceo-mint);

  /** completed (wachtend op goedkeuring) **/
  --ion-color-completed: #e8f6f5;
  --ion-color-completed-rgb: 232, 246, 245;
  --ion-color-completed-contrast: var(--lyceo-dark-blue);
  --ion-color-completed-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-completed-shade: #11a295;
  --ion-color-completed-shade-contrast: var(--lyceo-mint-contrast);
  --ion-color-completed-tint: var(--lyceo-mint);

  /** approved (Goedgekeurd) **/
  --ion-color-approved: #e4e6e9;
  --ion-color-approved-rgb: 228, 230, 233;
  --ion-color-approved-contrast: var(--lyceo-dark-blue);
  --ion-color-approved-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-approved-shade: #848a98;
  --ion-color-approved-shade-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-approved-tint: #848a98;

  /** final (Afgerond) **/
  --ion-color-final: #e4e6e9;
  --ion-color-final-rgb: 228, 230, 233;
  --ion-color-final-contrast: var(--lyceo-dark-blue);
  --ion-color-final-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-final-shade: #848a98;
  --ion-color-final-shade-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-final-tint: #848a98;

  /** cancelled (Geannuleerd) **/
  --ion-color-cancelled: #e4e6e9;
  --ion-color-cancelled-rgb: 228, 230, 233;
  --ion-color-cancelled-contrast: var(--lyceo-dark-blue);
  --ion-color-cancelled-contrast-rgb: var(--lyceo-dark-blue-rgb);
  --ion-color-cancelled-shade: #848a98;
  --ion-color-cancelled-shade-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-cancelled-tint: #848a98;

  /** disabled (Conflict etc. niet bestaande state) **/
  --ion-color-disabled: #e4e6e9;
  --ion-color-disabled-rgb: 228, 230, 233;
  --ion-color-disabled-contrast: #a5a9b4; // --lyceo-dark-blue with 32 opacity
  --ion-color-disabled-contrast-rgb: 165, 169, 180;
  --ion-color-disabled-shade: #848a98;
  --ion-color-disabled-shade-contrast: var(--lyceo-dark-blue-contrast);
  --ion-color-disabled-tint: #848a98;
}

.ion-color-unassigned {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-unassigned) !important;
  --ion-color-base-rgb: var(--ion-color-unassigned-rgb) !important;
  --ion-color-contrast: var(--ion-color-unassigned-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-unassigned-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-unassigned-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-unassigned-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-unassigned-tint) !important;
  /* stylelint-enable declaration-no-important */
}

.ion-color-approached {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-approached) !important;
  --ion-color-base-rgb: var(--ion-color-approached-rgb) !important;
  --ion-color-contrast: var(--ion-color-approached-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-approached-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-approached-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-approached-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-approached-tint) !important;
  /* stylelint-enable declaration-no-important */
}

.ion-color-waiting-for-documents {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-waiting-for-documents) !important;
  --ion-color-base-rgb: var(--ion-color-waiting-for-documents-rgb) !important;
  --ion-color-contrast: var(--ion-color-waiting-for-documents-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-waiting-for-documents-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-waiting-for-documents-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-waiting-for-documents-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-waiting-for-documents-tint) !important;
  /* stylelint-enable declaration-no-important */
}

.ion-color-waiting-for-confirmation {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-waiting-for-confirmation) !important;
  --ion-color-base-rgb: var(--ion-color-waiting-for-confirmation-rgb) !important;
  --ion-color-contrast: var(--ion-color-waiting-for-confirmation-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-waiting-for-confirmation-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-waiting-for-confirmation-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-waiting-for-confirmation-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-waiting-for-confirmation-tint) !important;
  /* stylelint-enable declaration-no-important */
}

.ion-color-draft {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-draft) !important;
  --ion-color-base-rgb: var(--ion-color-draft-rgb) !important;
  --ion-color-contrast: var(--ion-color-draft-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-draft-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-draft-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-draft-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-draft-tint) !important;
  /* stylelint-enable declaration-no-important */
}

.ion-color-provisionally-confirmed {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-provisionally-confirmed) !important;
  --ion-color-base-rgb: var(--ion-color-provisionally-confirmed-rgb) !important;
  --ion-color-contrast: var(--ion-color-provisionally-confirmed-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-provisionally-confirmed-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-provisionally-confirmed-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-provisionally-confirmed-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-provisionally-confirmed-tint) !important;
  /* stylelint-enable declaration-no-important */
}

.ion-color-confirmed {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-confirmed) !important;
  --ion-color-base-rgb: var(--ion-color-confirmed-rgb) !important;
  --ion-color-contrast: var(--ion-color-confirmed-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-confirmed-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-confirmed-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-confirmed-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-confirmed-tint) !important;
  /* stylelint-enable declaration-no-important */
}

.ion-color-completed {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-completed) !important;
  --ion-color-base-rgb: var(--ion-color-completed-rgb) !important;
  --ion-color-contrast: var(--ion-color-completed-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-completed-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-completed-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-completed-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-completed-tint) !important;
  /* stylelint-enable declaration-no-important */
}

.ion-color-approved {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-approved) !important;
  --ion-color-base-rgb: var(--ion-color-approved-rgb) !important;
  --ion-color-contrast: var(--ion-color-approved-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-approved-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-approved-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-approved-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-approved-tint) !important;
  /* stylelint-enable declaration-no-important */
}

.ion-color-final {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-final) !important;
  --ion-color-base-rgb: var(--ion-color-final-rgb) !important;
  --ion-color-contrast: var(--ion-color-final-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-final-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-final-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-final-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-final-tint) !important;
  /* stylelint-enable declaration-no-important */
}

.ion-color-cancelled {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-cancelled) !important;
  --ion-color-base-rgb: var(--ion-color-cancelled-rgb) !important;
  --ion-color-contrast: var(--ion-color-cancelled-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-cancelled-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-cancelled-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-cancelled-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-cancelled-tint) !important;
  /* stylelint-enable declaration-no-important */
}

.ion-color-disabled {
  /* stylelint-disable declaration-no-important */
  --ion-color-base: var(--ion-color-disabled) !important;
  --ion-color-base-rgb: var(--ion-color-disabled-rgb) !important;
  --ion-color-contrast: var(--ion-color-disabled-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-disabled-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-disabled-shade) !important;
  --ion-color-shade-contrast: var(--ion-color-disabled-shade-contrast) !important;
  --ion-color-tint: var(--ion-color-disabled-tint) !important;
  /* stylelint-enable declaration-no-important */
}
