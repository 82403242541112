ion-card[layout='full-width'] {
  margin-bottom: 4px;
  margin-inline: 0;
  border-radius: 0;

  ion-card-header {
    padding: 16px 20px 0;

    ion-card-title {
      font-size: 1.2rem;
      font-weight: var(--font-weight-medium);
    }
  }

  ion-card-content {
    font-size: 0.875rem;
    line-height: normal;

    p {
      font-size: 0.875rem;
      line-height: normal;
    }
  }

  ion-label p {
    color: var(--ion-text-color);
  }

  ion-item {
    --border-color: var(--lyceo-dark-blue-99);
    --detail-icon-opacity: 1;
    --detail-icon-font-size: 1.2rem;
    font-size: 0.875rem;

    &:not([fill='outline']) {
      --min-height: 0;
    }

    fa-icon {
      font-size: 1rem;
      margin-inline-end: 12px;
    }

    ion-label {
      margin: 0 0 8px;
    }
  }

  ion-item-divider {
    min-height: 1px;
    --ion-item-border-color: var(--lyceo-dark-blue-99);
  }
}
